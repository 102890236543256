<!--
 * @Author: John
 * @Date: 2022-02-22 11:45:19
 * @LastEditTime: 2022-03-08 10:00:19
 * @LastEditors: John
 * @Description: 当前定投列表
 * @FilePath: \jjms_ui\src\views\dtgl\add.vue
 * @Copyright John
-->
<template>
    <div>
        <my-breadcrumb :data="breadcrumbData" />
        <div class="content-wrap">
            <add-dt-form :data="null" :isDialog="false" />
        </div>
    </div>
</template>
<script>
import myBreadcrumb from '@/components/my-breadcrumb.vue'
import addDtForm from '@/components/add-dt.vue'

export default {
    name: 'tjdt',
    components: {
        myBreadcrumb,
        addDtForm
    },
    data() {
        return {
            breadcrumbData: [
                {title: '定投管理'},
                {title: '添加定投', url: '/main/dtgl-add'}
            ],
        }
    }
}
</script>
